import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  TouchableHighlight,
  Platform,
  Dimensions,
  Image,
  ScrollView,
} from "react-native";
import supabase from "../supabase";
import AppIconImage from "../assets/icon.png";
import Post from "./Post";
import { HeaderPad } from "./Components";
import MoreModal from "./MoreModal";

// const Tag = ({ tag }) => {
//   return (
//     <View
//       style={{
//         padding: 8,
//         paddingHorizontal: 16,
//         backgroundColor: "#222",
//         borderRadius: 32,
//       }}
//     >
//       <Text
//         style={{
//           color: "white",
//           fontWeight: "600",
//         }}
//       >
//         {tag}
//       </Text>
//     </View>
//   );
// };

const App = () => {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState("");
  const [databaseError, setDatabaseError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [postForModal, setPostForModal] = useState(null);
  // const [tags] = useState(["Journal", "Work", "Personal", "School"]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      onShiftEnterPressed();
    }
  };

  const onShiftEnterPressed = () => {
    createPost();
  };

  const fetchPosts = async () => {
    setLoading(true);

    const { data, error } = await supabase
      .from("posts")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      setDatabaseError(error);
    } else if (data) {
      setPosts(data);
      setLoading(false);
    }
  };

  const createPost = async () => {
    if (!post) return;
    const { data, error } = await supabase
      .from("posts")
      .insert([{ message: post }])
      .select("*");
    if (error) {
      setDatabaseError(error);
    } else if (data) {
      console.log(data);
      setPost("");
      await fetchPosts();
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchPosts();
    };
    initialize();
    if (Platform.OS === "web") {
      // Add the keydown event listener for web only
      // eslint-disable-next-line no-undef
      window.addEventListener("keydown", handleKeyDown);

      // Clean up the keydown event listener when the component is unmounted
      return () => {
        // eslint-disable-next-line no-undef
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  const quarterScreenHeight = Dimensions.get("window").height / 4;

  const narrowScreen =
    Dimensions.get("window").width < Dimensions.get("window").height;

  return (
    <View style={styles.rootContainer}>
      <StatusBar style="light" />
      <HeaderPad />
      <MoreModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        fetchPosts={fetchPosts}
        post={postForModal}
      />
      <View style={styles.pageContainer}>
        <ScrollView style={{ flex: 1 }}>
          <View style={{ height: quarterScreenHeight * 1.5 }}>
            <Image
              source={AppIconImage}
              style={styles.iconLogo}
            />
            <Text style={styles.sectionHeader}>Post</Text>
            <TextInput
              onKeyPress={handleKeyDown}
              multiline
              style={styles.postInputBox}
              placeholder="What's on your mind?"
              placeholderTextColor="gray"
              onChangeText={(text) => setPost(text)}
              value={post}
              cursorColor={"white"}
              selectionColor={"white"}
            />
            {/* <View
              style={{
                flexDirection: "row",
                paddingHorizontal: 8,
                gap: 8,
                flexWrap: "wrap",
              }}
            >
              {tags.map((tag) => (
                <Tag
                  tag={tag}
                  key={tag}
                />
              ))}
            </View> */}
            {narrowScreen && post && (
              <TouchableHighlight
                style={styles.postButton}
                onPress={createPost}
              >
                <Text style={styles.bodyText}>Post</Text>
              </TouchableHighlight>
            )}
          </View>

          <View style={{ flex: 1, marginTop: 16 }}>
            <Text style={styles.sectionHeader}>Posted</Text>
            {databaseError && (
              <Text style={{ color: "white" }}>{databaseError.message}</Text>
            )}
            {posts.map((post) => (
              <Post
                key={post.id}
                post={post}
                fetchPosts={fetchPosts}
                setDatabaseError={setDatabaseError}
                setPostForModal={setPostForModal}
                setModalOpen={setModalOpen}
              />
            ))}
            {!loading ? (
              <Post
                post={{
                  id: 1,
                  message:
                    "Hey, this is the first post! It's not stored on the database, so if you can't see any other posts, you're probably offline or there are no posts yet.",
                  created_at: "2023-06-08T09:22:45.253848+00:00",
                }}
                unhideable
              />
            ) : null}
            <View style={{ height: quarterScreenHeight / 2 }} />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: "black",
    alignItems: "center",
    justifyContent: "center",
  },
  pageContainer: {
    flex: 1,
    backgroundColor: "black",
    maxWidth: 768,
    width: "100%",
  },
  iconLogo: {
    width: 48,
    height: 48,
    marginTop: 16,
    marginLeft: 8,
    marginBottom: 16,
  },
  sectionHeader: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    marginHorizontal: 16,
    marginBottom: 8,
  },
  postInputBox: {
    backgroundColor: "black",
    color: "white",
    padding: 16,
    fontSize: 16,
    flex: 1,
    outlineStyle: "none",
    lineHeight: 24,
  },
  postButton: {
    backgroundColor: "#444",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    marginHorizontal: 8,
    borderRadius: 8,
    position: "absolute",
    bottom: 8,
    width: "95%",
  },
  bodyText: {
    color: "white",
    fontSize: 16,
  },
});

export default App;
