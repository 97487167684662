import "react-native-reanimated";
import "react-native-gesture-handler";
import React from "react";
import Main from "./src/App";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { LogBox } from "react-native";

LogBox.ignoreLogs(["No storage", `outline`]);

const App = () => {
  return (
    <SafeAreaProvider>
      <Main />
    </SafeAreaProvider>
  );
};

export default App;
