import { createClient } from "@supabase/supabase-js";
import { setupURLPolyfill } from "react-native-url-polyfill";
import { Platform } from "react-native";
// if platform is not web, setup polyfill
if (Platform.OS !== "web") {
  setupURLPolyfill();
}

const supabaseUrl = "https://koukzefzfwtwizcebkzf.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtvdWt6ZWZ6Znd0d2l6Y2Via3pmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYwNDk0MjUsImV4cCI6MjAwMTYyNTQyNX0.jT6Mj-DwjfDAkz-fI4wN7EJYzycgK2Sz3syxDxVa3eM";

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default supabase;
