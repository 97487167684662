import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Text,
  View,
  Alert,
  TouchableWithoutFeedback,
  Pressable,
} from "react-native";
import supabase from "../supabase";

const MoreModal = ({ modalOpen, setModalOpen, post, fetchPosts }) => {
  const deletePost = async () => {
    const { data, error } = await supabase
      .from("posts")
      .update({ hidden: true })
      .eq("id", post.id)
      .select("*");
    if (error) {
      Alert.alert("Error", error.message);
    } else if (data) {
      console.log(data);
      await fetchPosts();
    }
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalOpen}
      onRequestClose={() => {
        setModalOpen(!setModalOpen);
      }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          setModalOpen(!setModalOpen);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: 24,
          }}
        >
          <Pressable
            onPress={async () => {
              await deletePost();
              setModalOpen(!setModalOpen);
            }}
            style={{
              backgroundColor: "#222",
              padding: 24,
              borderRadius: 16,
            }}
          >
            <Text style={{ color: "white" }}>Delete post</Text>
          </Pressable>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

MoreModal.propTypes = {
  modalOpen: PropTypes.any,
  post: PropTypes.any,
  setModalOpen: PropTypes.func,
  fetchPosts: PropTypes.func,
};

export default MoreModal;
