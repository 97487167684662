export const formatDate = (thisDate) => {
  const now = new Date().getTime();
  const date = new Date(thisDate).getTime();
  let elapsed = (now > date ? now - date : date - now) / 1000;

  const getTime = (primaryName, value) => {
    const newValue = Math.floor(value);
    const primaryLabel = newValue > 1 ? `${primaryName}s` : primaryName;
    return `${newValue} ${primaryLabel}`;
  };

  if (elapsed <= 60) return "less than a minute";
  elapsed = elapsed / 60;
  if (elapsed < 60) return getTime("minute", elapsed);
  elapsed = elapsed / 60;
  if (elapsed < 24) return getTime("hour", elapsed);
  elapsed = elapsed / 24;
  if (elapsed < 30) return getTime("day", elapsed);
  const daysElapsed = elapsed;
  elapsed = elapsed / 30;
  if (daysElapsed / 365 < 1) return getTime("month", elapsed);
  elapsed = daysElapsed / 365;
  if (elapsed) return getTime("year", elapsed);
};
