import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const HeaderPadComponent = () => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        height: insets.top,
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    />
  );
};

HeaderPadComponent.displayName = "HeaderPad";

const HeaderPad = React.memo(HeaderPadComponent);

export { HeaderPad };
