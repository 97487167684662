import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import {
  Text,
  View,
  TouchableHighlight,
  Animated,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { formatDate } from "./utils";

const Post = ({ post, unhideable, setModalOpen, setPostForModal }) => {
  const { hidden, message, created_at } = post;
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: Platform.OS !== "web" ? true : false,
      }),
    ]).start();
  }, []);

  if (hidden) return null;
  return (
    <Animated.View
      style={{
        backgroundColor: "#222",
        padding: 16,
        borderRadius: 16,
        margin: 8,
        gap: 4,
        flexDirection: "row",
        opacity: opacity,
      }}
    >
      <View style={{ flex: 1, gap: 4 }}>
        <Text style={{ color: "gray", fontSize: 16 }}>
          {formatDate(created_at)}
        </Text>
        <Text style={{ color: "white", fontSize: 16, lineHeight: 24 }}>
          {message}
        </Text>
      </View>
      {!unhideable && (
        <View>
          <TouchableHighlight
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: 8,
            }}
            onPress={() => {
              setPostForModal(post);
              setModalOpen(true);
            }}
          >
            <Feather
              name="more-horizontal"
              size={20}
              color="white"
            />
          </TouchableHighlight>
        </View>
      )}
    </Animated.View>
  );
};

Post.propTypes = {
  fetchPosts: PropTypes.func,
  post: PropTypes.shape({
    created_at: PropTypes.any,
    hidden: PropTypes.any,
    id: PropTypes.any,
    message: PropTypes.any,
  }),
  setDatabaseError: PropTypes.func,
  setModalOpen: PropTypes.func,
  setPostForModal: PropTypes.func,
  unhideable: PropTypes.any,
};

export default Post;

{
  /* 
const PostOG = ({ post, fetchPosts, unhideable }) => {
  const { hidden, message, created_at } = post;
  if (hidden) return null;
  return (
    <View
      style={{
        backgroundColor: "#222",
        padding: 16,
        borderRadius: 16,
        margin: 8,
        gap: 4,
        flexDirection: "row",
      }}
    >
      <View style={{ flex: 1, gap: 4 }}>
        <Text style={{ color: "gray", fontSize: 16 }}>
          {formatDate(created_at)}
        </Text>
        <Text style={{ color: "white", fontSize: 16 }}>{message}</Text>
      </View>
      {!unhideable && (
        <View>
          <TouchableHighlight
            style={{
              backgroundColor: "#444",
              alignItems: "center",
              justifyContent: "center",
              padding: 12,
              marginHorizontal: 8,
              borderRadius: 8,
            }}
            onPress={async () => {
              const { data, error } = await supabase
                .from("posts")
                .update({ hidden: true })
                .eq("id", post.id)
                .select("*");
              if (error) {
                setDatabaseError(error);
              } else if (data) {
                console.log(data);
                await fetchPosts();
              }
            }}
          >
            <Feather
              name="trash-2"
              size={20}
              color="white"
            />
          </TouchableHighlight>
        </View>
      )}
    </View>
  );
};
*/
}
